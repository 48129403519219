<template>
  <section>
    <title-section
      title="Productos"
    >
      <div class="level-item">
        <button
          class="button button_micro_dark"
          :class="{ 'is-loading': loading }"
          :disabled="loading"
          @click="controlModalImporter"
        >
          IMPORTAR
        </button>
      </div>
      <div class="level-item">
        <router-link class="button button_micro_dark" to="/productos/agregar-producto" :disabled="loading">
          AGREGAR PRODUCTO
        </router-link>
      </div>
    </title-section>
    <main-products ref="mainProductsRef" />
    <a-modal
      title="Subir archivo de productos"
      :visible="importerVisible"
      @cancel="controlModalImporter">
      <template slot="footer">
        <button class="button button_micro_dark">
          Continuar
        </button>
      </template>
      <div class="has-text-centered">
        <p>
          Sube tu archivo excel <b>(en formato CSV)</b> con los datos correspondientes de tus productos. Recuerda que deberás subir primero sus imágenes a AWS y usar el mismo nombre en de la imagen en tu archivo.
          <download-csv class="pointer" :data="importProducts" name="plantilla_productos.csv" style="padding-top: 10px">
            <small style="color: #000">Descarga plantilla</small>
          </download-csv>
        </p>
        <div style="padding-top: 10px" v-if="!loading">
          <input type="file" ref="importer" @change="onFileChange" accept=".csv" v-show="false" />
          <a-button @click="onSelectFile"> <a-icon type="upload" /> Seleccionar archivo </a-button>
        </div>
      </div>
    </a-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import JsonCSV from 'vue-json-csv'
import Papa from 'papaparse'
import Swal from 'sweetalert2'
import importProducts from '@/constant/importProducts'

Vue.component('downloadCsv', JsonCSV)

export default {
  name: 'Products',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    MainProducts: () => import('@/components/Products/MainProducts.vue')
  },
  data () {
    return {
      importProducts,
      importerVisible: false,
      loading: null
    }
  },
  methods: {
    ...mapActions(['importProductsList']),
    controlModalImporter () {
      this.importerVisible = !this.importerVisible
    },
    onSelectFile () {
      this.$refs.importer.click()
    },
    onFileChange (e) {
      const _this = this
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        encoding: 'UTF-8',
        complete: function (results) {
          _this.controlModalImporter()
          Swal.fire({
            title: '¡Atención!',
            text: `Estás a punto de importar ${results.data.length} registro${results.data.length === 1 ? '' : 's'}. ¿Deseas continuar?`,
            showCancelButton: true,
            confirmButtonText: 'Sí, continuar',
            cancelButtonText: 'Cancelar',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'button button_micro_dark',
              cancelButton: 'button button_micro_dark_outlined'
            },
            reverseButtons: true
          }).then(async (result) => {
            if (result.isConfirmed) {
              _this.loading = true
              await _this.importProductsList(results.data)
              _this.$refs.mainProductsRef.getProducts()
              _this.loading = false
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 15px;
    width: 200px;
  }
  .button_micro_dark_outlined {
    border: solid 2px #262626;
  }
  .ant-btn:hover, .ant-btn:focus {
    color: #000 !important;
    background-color: #fff;
    border-color: #000 !important;
  }
  .pointer {
    cursor: pointer;
  }
</style>
